.list {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	&-item {
		margin: 1rem .5rem 0 .5rem;
		padding: 0 0 .5rem 0;
		border-bottom: 1px solid #ddd;
		width: 30%;
		overflow: auto;
		&-link {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			color: rgba(66, 66, 66, 1);
			padding: 20px 0;
			&:hover {
				h2, p {
					color: rgba(66, 66, 66, 1);
					text-decoration: underline;
				}
			}
			.item-info {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin: 0 0 5px 0;
			}
			.name {
				color: #669900;
			}
			img {
				width: 100%;
				height: auto;
				margin: 0 0 10px 0;
			}
			h2 {
				font-weight: bold;
				justify-self: flex-start;
				font-size: 16px;
				margin: 0;
			}
			p {
				// font-size: 14px;
				font-weight: 300;
				margin: 0;
			}
		}
	}
}

.no-article {
	font-size: 16px;
	font-weight: bold;
	padding: 20px 0;
	text-align: center;
}

.paginate-wrapper {
	text-align: center;
	margin: 40px 0 0;
}

@media(max-width: 768px) {
	.list {
		&-item {
			width: 45%;
			&-link {
				display: block;
			}
		}
	}
}

@media(max-width: 600px) {
	.list {
		&-item {
			width: 100%;
			&-link {
				display: block;
			}
		}
	}
}