$easeInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$turnDuration: 0.6s;
$border: whitesmoke;
$card: white;
$font-family: 'Open Sans', monospace;
$font-size: 1.8em;
$font-width: lighter;
$font-color: lighten(black, 20%);

.flipDigitsContainer {
    margin: 0px 10px 0px 10px;
    padding-bottom: 10px;
    width: 100%;
	background-color: #72BA3E;
	border-radius: 5px;

    @media (max-width: 540px) {
        margin: 0;
        border-radius: 0px;
        border: 1px solid #72BA3E;
    }
}

.card-wrapper {
    padding: 15px 0;
    width: 40px;
    margin: 0 auto;
}

.card {
    font-size: $font-size;
    font-family: $font-family;
    font-weight: $font-width;
    color: $font-color;
    position: relative;
    width: 40px;
    height: 25px;
    overflow: hidden;
    .number-group {
        display: inline-block;
        width: 50%;
        transition: transform .25s cubic-bezier(.64,.57,.67,1.53);
    }
    .number {
        position: relative;
        width: 100%;
        margin: 0;
        color: white;
        font-weight: 900;
    }
}
	
.unitText {
    color: white;
    font-weight: 900;
    font-size: 25px;
    
    @media (max-width: 540px) {
        font-size: 16px;
    }
}