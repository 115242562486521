.order-period {
	display: flex;
	color: #ccc;
	width: 100%;
	padding: 0;
	&-item {
		width: 50%;
		text-align: center;
		list-style-type: none;
		cursor: pointer;
		border-bottom: 2px solid #ccc;
		padding: 6px 10px;
		&-active {
			font-weight: bold;
			color: #3C4858;
			border-bottom: solid 2px #72BA3E;
		}
	}
	&-list {
		display: none;
		&-active {
			display: block;
		}
	}
}

.order-plan-img {
	padding: 0px !important;
	img {
		width: 100%;
	}
}

.order-plan-img-lite {
	padding: 0px !important;
	text-align: center;
	img {
		height: 77px;
	}
}

.spec-wrapper {
	align-items: center;
	display: flex;
}

.price-plan {
	margin: 0;
	text-align: center;
}

.discount {
	&-wrapper {
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
	&-off {
		background: #fddce0;
		padding: 0 4px;
		border-radius: 6px;
		font-size: 12px;
		font-weight: normal;
		margin: 0;
		color: #f64f64;
	}
	&-month {
		text-decoration: line-through;
		font-size: 14px;
		font-weight: normal;
		color: #f64f64;
		* {
			color: #f64f64;
		}
	}
	&-year {
		font-size: 17px;
		color: #3C4858;
		margin: 0 0 5px;
		h5 {
			font-weight: normal;
		}
	}
}

.toggle-button-container {
	width: 100%;
	text-align: center;
}

@media (max-width: 599px) {
	.toggle-button-container {
		margin-top: 25px;
	}
}