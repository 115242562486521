.lp-wrapper {
	width: 100%;
	max-width: 1140px;
	height: auto;
	position: relative;
	margin: 0 auto;
	padding: 48px 15px;
	&-top {
		padding: 160px 15px 48px;
	}
}

@media (max-width: 1600px) {
	.lp-wrapper {
		&-top {
			padding: 24px 15px 48px;
		}
	}	
}

@media (max-width: 825px) {
	.lp-wrapper {
		&-top {
			padding: 110px 15px 48px;
		}
	}
}

.hero {
	text-align: center;
	&-title {
    font-size: 52px;
		color: #020202;
    margin: 1.75rem 0 0.875rem;
    margin-top: 30px;
    min-height: 32px;
    font-family: IBM Plex Sans;
    font-weight: 700;
    text-decoration: none;
	}
	&-text {
		font-size: 20px;
		margin: 0;
		white-space: pre-line;
		line-height: 1.6;
	}
	&-btn {
		color: #fff !important;
    width: 315px !important;
    padding: 6px 24px !important;
    font-size: 22px !important;
    margin-top: 50px !important;
    border-radius: 35px !important;
    text-transform: none !important;
	}
	&-work {
		width: 60%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}
}


@media (max-width: 825px) {
	.hero {
		&-work {
			width: 100%;
		}
		&-title {
			font-size: 30px;
		}
	}
}


.lp-content {
	display: flex;
	align-items: center;
	&-wrapper {
    background: rgb(238, 238, 238);
		padding: 60px 15px;
		&:nth-child(odd) {
			background: #fafafa;
		}
	}
	&-img {
		width: 200px;
		text-align: center;
		img {
			width: 100%;
		}
	}
	&-group {
		width: calc(100% - 200px);
		padding-left: 50px;
		&-title {
			margin: 0 0 24px;
			font-size: 28px;
			font-family: IBM Plex Sans;
			font-weight: bold;
		}
		&-text {
			font-size: 20px;
			margin: 0;
			white-space: pre-line;
			line-height: 1.6;	
		}
		ul {
			margin: 10px 0;
			padding: 0 0 0 10px;
			li {
				list-style-type: none;
				font-size: 14px;
				position: relative;
				padding-left: 10px;
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: "-";
				}
			}
		}
	}
	&-right {
		flex-direction: row-reverse;
		.lp-content-group {
			padding-right: 50px;
			padding-left: 0;
		}
	}
}


@media (max-width: 825px) {
	.lp-content {
		display: block;
		&-wrapper {
			.lp-wrapper {
				padding: 48px 15px 0;
				&:last-child {
					padding: 48px 15px;
				}
			}
		}
		&-img {
			width: 100%;
			text-align: center;
			margin: 0 0 20px;
			img {
				width: auto;
			}
		}
		&-group {
			width: 100%;
			padding-left: 0;
			&-title {
				text-align: center;
			}
		}
		&-right {
			.lp-content-group {
				padding-right: 0;
			}
		}
	}
	
}


.lp-table {
	width: 100%;
	th, td {
		width: 25%;
		padding: 10px 20px;
		border-right: 1px solid #d2d2d2;
		height: 44px;
		background: #fff;
		min-width: 146px;
		vertical-align: middle;
		a {
			color: #002774;
			text-decoration: underline;
			&:hover {
				opacity: .7;
				text-decoration: none;
			}
		}
	}
	th {
		text-align: center;
		padding: 30px 20px;
		font-size: 18px;
	}
	tbody {
		td {
			text-align: center;
			svg {
				fill: #666;
				vertical-align: bottom;
				&.green {
					fill: #72BA3E;
				}
				&.red {
					fill: red;
				}
			}
		}
		tr {
			&:nth-child(odd) {
				th, td {
					background: #fafafa;
				}
			}
		}
	}
	&-strong {
		color: #72BA3E;
	}
	&-title {
		text-align: center;
		color: #020202;
    padding: 0 0 24px 0;
    font-size: 52px;
    font-family: IBM Plex Sans;
    font-weight: bold;
	}
	&-wrapper {
		overflow-x:scroll;
		width: 100%;
	}
	&-sticky {
		position: sticky;
		left: 0;
		font-weight: bold;
		text-align: left !important;
	}
	&-section {
		width: 50%;
		&:last-child {
			td, th {
				&:last-child {
					border: 0px !important;
				}
			}
		}
	}
}

.top {
	&-hero {
		&-title {
			color: #020202;
			margin: -13px 12px;
			font-size: 52px;
			font-family: IBM Plex Sans;
			font-weight: bold;
		}
		&-animation {
			position: relative;
			display: inline-block;
			&-copy {
				opacity: 0;
			}
			&-text {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%);
				width: 100%;
				text-align: left;
				color: #75cb42;
			}
		}
	}
}

@media (min-width: 1250px) {
  .top {
		&-hero {
			&-title {
				font-size: 52px !important;
			}
		}
	}
}

@media (min-width: 959.96px) and (max-width: 1249px) {
	.top {
		&-hero {
			&-title {
				font-size: 4.16vw !important;
			}
		}
	}
}

@media (max-width: 959.95px) {
	.top {
		&-hero {
			&-title {
				font-size: 30px;
				text-align: center;
				line-height: 1.4em;
			}
			&-animation {
				padding: 0 4px;
				&::after {
					content: "";
					display: block;
					width: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					background: #75cb42;
					height: 2px;
					border-radius: 2px;
					opacity: .6;
				}
				&-text {
					width: calc(100% - 8px);
					text-align: center;
				}
			}
		}
	}
}
@media (max-width: 599.95px) {
	.top {
		&-hero {
			&-title {font-size: 30px;
			}
		}
	}
}
