.youtubevideo {
    border: 1px solid #fff;
}

@media (max-width: 320px) {
    .youtubevideo {
        width: 100%;
    }
}

@media (min-width: 360px) {
    .youtubevideo {
        width: 100%;
        height: 180px;
    }
}

@media (min-width: 411px) {
    .youtubevideo {
        width: 100%;
        height: 250px;
    }
}

@media (min-width: 768px) {
    .youtubevideo {
        width: 330px;
        height: 200px;
    }
}

@media (min-width: 1024px) {
    .youtubevideo {
        width: 450px;
        height: 320px;        
    }
}

@media (min-width: 1280px) {
    .youtubevideo {
        width: 650px;
        height: 410px;
    }
}