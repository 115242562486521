.cardText {
    background-color: #f2f2f2;
    text-align: left;
}

// iphone se width
@media (max-width: 320px) {
    .video {
        width: 250px;
        height: 170px;
    }
}

// galaxy s5 width
@media (min-width: 360px) {
    .video {
        width: 290px;
        height: 185px;
    }
}

// iphone x and iphone 8 width
@media (min-width: 375px) {
    .video {
        width: 300px;
        height: 200px;
    }
}

// iphone 8 plus width
@media (min-width: 414px) {
    .video {
        width: 340px;
        height: 225px;
    }
}

// ipad view
@media (min-width: 768px) {
    .cardText {
        min-height: 278px;
    }

    .video {
        width: 425px;
        height: 275px;
    }
}

// ipad pro view
@media (min-width: 1024px) {
    .cardText {
        min-height: 290px;
    }
}

@media (min-width: 1280px) {
    .cardText {
        min-height: 260px;
    }

    .video {
        width: 475px;
        height: 315px;
    }
}