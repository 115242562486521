
.btn {
	&-warapper {
		clear: both;
		text-align: center;
		padding: 20px 0 40px;
		a, button {
			display: inline-block;
			color: #ffffff;
			background: #07bc0c;
			padding: 12px 20px;
			border-radius: 6px;
			text-decoration: none;
			outline: none;
			border: none;
			cursor: pointer;
			&:hover {
				color: #fff;
				opacity: .7;
			}
			&:disabled {
				cursor: default;
				color: rgba(0, 0, 0, 0.26);
				background-color: rgba(0, 0, 0, 0.12);
			}
			+ a , + button {
				margin-left: 10px;
			}
		}
	}
	&-submit {
		background: #07bc0c;
	}
	&-back {
		background: #e74c3c !important;
	}
}