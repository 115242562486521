.notfound-wrapper {
	margin: 95px 0 0;
	padding: 0;
	background: rgb(238, 238, 238);
	display: flex;
	align-items: center;
	position: relative;
}

.notfound-inner {
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	padding: 140px 0 100px;
	text-align: center;
	img {
		width: 24%;
	}
}

.notfound {
	&-title {
		margin: 40px 0 30px;
    font-size: 3rem;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #72BA3E;
    position: relative;
    font-weight: bold;
		span {
			position: relative;
			&::after {
				left: 0;
				right: 0;
				width: 100%;
				bottom: -16px;
				height: 2px;
				margin: 0 auto;
				content: "";
				position: absolute;
				background: #72BA3E;
			}
		}
	}
	&-text {
		font-size: x-large;
		padding: 10px 0 10px;
	}
}