.footer {
	color: #fff;
	width: 100%;
	padding: 48px 20px 48px 20px;
	position: relative;
	font-size: 16px;
	background: linear-gradient(45deg, #01234c 0%, #00287d 100%);
	font-family: IBM Plex Sans;
	margin-bottom: -25px;
	&-inner {
    margin: 0 auto;
    max-width: 1140px;
	}
	&-nav {
		width: 100%;
		padding: 0 0 30px;
		display: flex;
		border-bottom: 1px solid #707070;
	}
	&-why ,
	&-second {
		padding: 0 20px 0 0;
	}
	&-why {
		width: 47%;
	}
	&-second {
		width: 23%;
	}
	&-legal {
		width: 30%;
	}
	&-list {
		+ dl {
			margin: 40px 0 0;
		}
		dt {
			font-size: 16px;
			font-weight: bold;
			margin: 0 0 19px;
			text-transform: uppercase;
		}
		dd {
			margin: 0;
			ul {
				margin: 0;
				padding: 0;
				list-style-type: none;
				li {
					margin: 0 0 8px;
					a {
						color: #fff;
						font-size: 16px;
						display: block;
						&:hover {
							opacity: .7;
						}
					}
				}
			}
		}
	}
	&-address {
		text-align: right;
		padding: 38px 0 0;
	}
	&-social {
		i {
			padding: 1px;
			margin: 5px 5px 5px 5px;
		}
	}
	&-copy {
		color: #fff;
		font-size: 16px;
		font-weight: bold;
	}
	&-payment-icons-all {
		margin: -10px 0 0 -4px;
	}
	&-payment-img {
		display: inline-block;
		width: 40px;
		height: 30px;
		margin: 6px;
	}
	&-payment-img-mastercard {
		display: inline-block;
		width: 60px;
		height: 30px;
		margin: 6px;
	}
	&-payment-img-discover {
		display: inline-block;
		width: 50px;
		height: 30px;
		margin: 6px;
	}
	&-payment-img-visa {
		display: inline-block;
		width: 30px;
		height: 30px;
		margin: 6px;
	}
	&-icon-color {
		color: #fff;
		&:hover, &:focus, &:visited {
			color: #fff;
		}
	}
}

@media (max-width: 959px) {
	.footer {
		padding: 40px 28px 70px;
		&-nav {
			padding: 0 0 40px;
			display: block;
		}
		&-why ,
		&-second,
		&-legal {
			width: 100%;
			padding: 0 0 0 0;
		}
		&-why {
			.footer-list-solutions-for-you {
				margin: 0;
			}
		}
		&-list {
			margin: 34px 0 0;
			+ dl {
				margin: 34px 0 0;
			}
		}
		&-address {
			text-align: center;
			padding: 40px 0 0;
		}
		&-copy {
			margin: 0 0 10px;
		}
	}
}
