
.banner--wrapper {
    width: 100%;
    margin: auto; 
    margin-bottom: 10px;
    display: flex; 
    flex-direction: row wrap;
    align-items: center;
    background-color: #F3F3F3;
    .img--wrapper {
        width: 20%; 
        justify-self: flex-start;
        img {
            width: 100%;
            height: auto;
        }
    }
    .title--wrapper {
        padding: 0 0px 0 10px;
	width: 100%;
        h2 {
            width: 90%; 
            margin: auto;
            padding: 5px 0 5px 0;
        }
    }
}

@media (max-width: 991px) {
    .banner--wrapper {
        .img--wrapper {
            display: none;
            img {
                display: none;
            }
        }
        .title--wrapper {
            padding: 0;
            h2 {
                text-align: center;
            }
        }

    }
}

.cms-content {
    margin: auto;
    margin-bottom: 60px;
    overflow: hidden;
    line-height: 1.9;
    color: #3C4858;
    padding: 5px;
    h3 {
        text-align: left;
    }
    img.img {
        width: auto;
        max-width: 100%;
        height: auto;
    }        
    p, 
    li {
        font-size: 1em;
        font-weight: 400;
    }
    p {
        overflow: hidden;
    }
    code[class*="language-"], pre[class*="language-"] {
        color: black;
        text-shadow: 0 1px white;
        direction: ltr;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        line-height: 1.5;
        tab-size: 4;
        hyphens: none;
    }
    pre[class*="language-"] {
        background: #d9d9d9;
    }
    pre[class*="language-"] {
        padding: 1em;
        margin: .5em 0;
        overflow: auto;
    }
}

.page-blog {
    .cms-content {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: "PT Sans", "Verdana", "Helvetica", "Arial", sans-serif !important;
            font-weight: bold;
        }
        img {
            width: auto;
            max-width: 100%;
            height: auto;
        }
    }
}
