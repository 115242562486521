.page--wrapper {
    width: 80%;
    margin: auto;
	padding-bottom: 48px;
}

@media screen and (max-width: 768px) {
    .page--wrapper {
        width: 100%;
    }
}

.page-blog {
    font-family: "PT Sans", "Verdana", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
	h3 {
        font-family: "PT Sans", "Verdana", "Helvetica", "Arial", sans-serif !important;
        font-weight: 700;
	}
}

.content {
    &--wrapper {
        display: flex; 
        flex-direction: row;
        order: 0;
        margin-bottom: 15px;
    }
}

.share {
    &-pane {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 1em 2em 0 0;
        order: 0;
    }
    &-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        padding: 1em 0 1em 0;
        border-bottom: 1px solid #E0E0E0;
        :hover {
            cursor: pointer;
        }
    }
    &-icon {
        padding: 5px 10px 5px 10px;
    }
    &-desc {
        color: #3C4858;
        fontSize: .85rem;
    }
}
@media(max-width: 900px) {
    .share {
        &-item {
            flex-direction: column;
            align-items: center;
        }
    }
}

.list {
    width: 20%; 
    display: flex; 
    flex-direction: column; 
    border-left: 1px solid #E0E0E0;
    padding-left: 1em; 
    margin-left: 25px;
    order: 3;
    &-heading {
        margin: 0;
        padding: 1em 0 1em 0;
        color: #3C4858;
        font-size: .85rem;
        font-weight: bold;
        text-align: left;
        border-bottom: 1px solid #E0E0E0;
    }
    &-articles {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        padding: 0;
    }
    &-item {
        width: 100%;
        margin: 0;
        padding: 1em 0 1em 0;
		border-bottom: 1px solid #ddd;
        list-style: none;
    }
    &-promoImage {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    &-link {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: rgba(66, 66, 66, 1);
        &:hover {
            h2, p {
                color: rgba(66, 66, 66, 1);
                text-decoration: underline;
            }
        }
    }
    &-title {
        width: 100%;
        font-size: .75rem;
        line-height: normal;
    }
}

.modal {
    display: flex;
    alignItems: center;
    justifyContent: center;
}

.no-article {
	font-size: 16px;
	font-weight: bold;
	padding: 20px 0;
	text-align: center;
}

@media only screen and (max-device-width: 480px) {
    .content {
        &--wrapper {
            flex-direction: column;
            width: 100%;
            order: -1;
        }
    }
    .share {
        &-item {
            flex-flow: column;
            align-items: center;
            border: none;
        }
        &-pane {
            margin: auto;
            padding: 0 0 15px 0;
            flex-direction: row;
            width: 75%;
            justify-content: space-between;
            order: 1;
        }
    }
    .list {
        padding: 0;
        margin: 0 auto 15px auto;
        width: 100%;
        align-items: center;
        border: none;
        &-heading {
            border: none;
        }
        &-articles {
            flex-flow: row wrap;
        }
        &-item {
            padding: .5em;
            border-left: 1px solid #E0E0E0;
            // border-bottom: none;
        }
    }
}

.promoImage {
    width: 100%;
}