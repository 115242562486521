.cart-wrapper {
	width: 100%;
	overflow: hidden;
}

.cart-item {
	color: #3e3e3e;
	margin: 0 0 10px;
	dl {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #3e3e3e;
		margin: 0;
		> div {
			width: 100%;
			&:nth-child(3) {
				dt {
					text-align: right;
				}
				dd {
					text-align: right;
				}
			}
		}
		dt {
			border-bottom: 2px solid #3e3e3e;
			font-weight: bold;
			padding: 10px;
			text-align: center;
		}
		dd {
			margin: 0;
			padding: 10px;
			text-align: center;
		}
		+ dl {
			dt {
				display: none;
			}
		}
	}
	&-remove {
		cursor: pointer;
	}
}


@media (max-width: 825px) {
	.cart-item {
		color: #3e3e3e;
		margin: 0 0 30px;
		dl {
			display: block;
			margin: 0 0 20px;
			border: none;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			div {
				display: flex;
				justify-content: space-between;
				width: unset;
			}
			dt {
				border-bottom: none;
			}
			+ dl {
				dt {
					display: block;
				}
			}
			dd {
				padding: 0;
			}
		}
		.cart-mobile-individual-price {
			width: 100%;
			font-style: italic; 
			padding: 6px 0;
		    font-size: .95em;
		}
	}
}


.cart-total {
	color: #3e3e3e;
	float: right;
	width: 30%;
	margin: 0;
	div {
		display: flex;
		justify-content: flex-end;
		border-bottom: 1px solid #3e3e3e;
		&:last-child {
			border-bottom: none;
			dt,
			dd {
				font-size: 16px;
				font-weight: bold;
			}
		}
		dt ,
		dd {
			width: 50%;
			margin: 0;
			padding: 10px;
		}
		dt {
			text-align: left;
			font-weight: bold;
		}
		dd {
			text-align: right;
		}
	}
}

.cart-nothing {
	font-size: 2rem;
	text-align: center;
	padding: 40px 0;
}

@media (max-width: 825px) {
	.cart-total {
		width: 100%;
		float: none;
	}
}
